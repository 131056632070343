.background {
    overflow: hidden;
    background: url('../img/background.jpg') center/cover no-repeat;
    height: 100vh;
}

.background::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5); 
  z-index: -1; 
}